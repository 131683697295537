import React,  { useState, useEffect, useCallback } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ShareButtons from '../components/share'
import switchCategory from '../utils/switchCategory.jsx';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SEO from '../components/seo'
import AdSense from 'react-adsense'
import PrevNext from '../utils/prevNext.jsx'
import Helmet from "react-helmet"
import  ThemeContext  from '../components/context/ThemeContext';
import Popup from '../components/popup'
import YouTubeIcon from '@material-ui/icons/YouTube';
import PropTypes from "prop-types"
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import niconico from '../../assets/images/niconico.png'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

class MovieList extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      isArticleVisible: false,
      timeout: false,
      articleTimeout: false,
      article: '',
      articleIndex:0,
      quote:'',
      playing:false,
      loading: 'is-loading',
    }
    //コールバック関数として実行する際にちゃんと関数内の「this」が機能するように、constructor()内でthisをbindしておく必要があります。
    this.handleOpenArticle = this.handleOpenArticle.bind(this)
    this.handleCloseArticle = this.handleCloseArticle.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount () {
    this.timeoutId = setTimeout(() => {
        this.setState({loading: ''});
    }, 100);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount () {
    if (this.timeoutId) {
        clearTimeout(this.timeoutId);
    }
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  //コンポーネントの外でもクリックイベントを検出するようにする
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleOpenArticle(article,quote,index) {

    switch (quote){
      case 'youtube':
        article= "https://www.youtube.com/embed/"+article + "?enablejsapi=1"
        break;
      case 'niconico':
        article = article + "?jsapi=1&playerId="+(index+1).toString()
        break;
      case 'tiktok':
        article = article + "?jsapi=1&playerId="+(index+1).toString()
      default:
        break;
    }

    this.setState({
      isArticleVisible: !this.state.isArticleVisible,
      article,
      quote,
      articleIndex:index+1
    })

    setTimeout(() => {
      this.setState({
        timeout: !this.state.timeout
      })
    }, 325)

    setTimeout(() => {
      this.setState({
        articleTimeout: !this.state.articleTimeout
      })
    }, 350)
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.isArticleVisible) {
        this.handleCloseArticle();
        switch(this.state.quote){
          case 'youtube':
            var player = document.getElementById(this.state.article)
            player.contentWindow.postMessage(
              JSON.stringify(
                { event: 'command', func: 'pauseVideo' }), 'https://www.youtube.com');
            break;
          case 'niconico':
            var player = document.getElementById(this.state.article)
            player.contentWindow.postMessage({
              eventName: "pause",
              playerId: this.state.articleIndex.toString(),
              sourceConnectorType: 1
            }, "https://embed.nicovideo.jp");
            break;
          case 'tiktok':
            var player = document.getElementById(this.state.article)
            player.contentWindow.postMessage(JSON.stringify(
              { event: 'command', func: 'pauseVideo' }), "https://www.tiktok.com/");
            break;
        }
      }
    }
  }

  handleCloseArticle() {

    this.setState({
      articleTimeout: !this.state.articleTimeout
    })

    setTimeout(() => {
      this.setState({
        timeout: !this.state.timeout
      })
    }, 325)

    setTimeout(() => {
      this.setState({
        isArticleVisible: !this.state.isArticleVisible,
        article: ''
      })
    }, 350)

  }

  render(){
    return(
      <>
      <nav>
      {
          Object.values(this.props.node).map(
            (onelink,index) =>{
              switch (this.props.type){
                case 'youtube':
              return(
                  <>
                  {/* 
                  href="javascript:;"で何もしないページに遷移するように指定
                  javascript: で始まるURLに移動しようとすると、後続のJavascriptコードが実行されます
                  このケースだと空なので何もしない
                  aタグは元々リンク専用のタグでないこともあり、ハイパーリンクの記法としてはhref属性が必要です。実際、href属性がないとリンクらしい見た目にはなりません。
                  aタグを使って強引にカーソルを変化させる場合に使う */}
                    <a href="javascript:;" onClick={() => {this.handleOpenArticle(onelink,this.props.type,index)}}>
                      <img src={"https://img.youtube.com/vi/"+onelink+"/mqdefault.jpg"}/>
                    </a>
                  </>
                )
              case 'niconico':
                return(
                  <>
                    <a href="javascript:;" onClick={() => {this.handleOpenArticle(onelink.video_url,this.props.type,index)}}>
                      <img src={onelink.thumbnail_url} style={{width:'320px',height:'180px'}}/>
                    </a>
                  </>
                )
              case 'tiktok':
                return(
                  <>
                    <a href="javascript:;" onClick={() => {this.handleOpenArticle(onelink.video_url,this.props.type,index)}}>
                      <img src={onelink.thumbnail_url} style={{width:'320px',height:'180px'}}/>
                    </a>
                  </>
                )
              }
            }
          )
        }
        </nav>
        <Popup
              isArticleVisible={this.state.isArticleVisible}
              timeout={this.state.timeout}
              articleTimeout={this.state.articleTimeout}
              article={this.state.article}
              onCloseArticle={this.handleCloseArticle}
              setWrapperRef={this.setWrapperRef}
              onPause={this.onPause}
              onPlay={this.onPlay}
              playing={this.state.playing}
              node={this.props.node}
              type={this.props.type}
            />
        </>
    )
  }
}

export default ({
  data: {
    allMovieJson: { nodes }
  }
  , pageContext}) => {


    const classes = useStyles();
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
return (
  <>
  <Helmet
    bodyAttributes={{
        class: 'globalnody'
    }}
    />
        <ThemeContext.Consumer>
        {theme => (
          <Layout page="posts" tabnumber={switchCategory(nodes[0].rough_genre[0])} themeContext={theme} template='movie'>
            <SEO title={nodes[0].title+ " | 話題に反応！"} description={nodes[0].title} image={nodes[0].top_image} />
            <main className="movie--main">
              <article>
              <header className="movie--header">
                
                <h1>{nodes[0].title}</h1>
                <ShareButtons shareTitle={nodes[0].title} shareUrl={nodes[0].fields.slug}></ShareButtons>
              </header>
              <Tabs
                value={value}
                fullWidth
                centered
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                >
                <Tab icon={<YouTubeIcon />} {...a11yProps(0)} style={{ color: '#FF5577',width:'40%' }} />
                <Tab label={<img src={niconico} style={{width:20,height:14}}/>} {...a11yProps(1)} style={{ color: '#FF5577' ,width:'40%'}} />
                {/*<Tab label={<img src="https://img.icons8.com/officel/16/000000/tiktok.png"/>} {...a11yProps(2)} style={{ color: '#FF5577' ,width:'40%'}} />*/}
              </Tabs>
              <TabPanel value={value} index={0}>
                <MovieList node={nodes[0].youtube} type='youtube'/>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <MovieList node={nodes[0].niconico} type='niconico'/>
              </TabPanel>
              {/*
              <TabPanel value={value} index={2}>
                <MovieList node={nodes[0].tiktok} type='tiktok'/>
              </TabPanel>*
              */}
         
         
        <AdSense.Google client="ca-pub-9496221164080653" slot="1273467658" format="auto"/> 

        <PrevNext prev={pageContext.prev} next={pageContext.next} />
        </article>
      </main>
    </Layout>
            )
          }
            </ThemeContext.Consumer>
    </>
  )
}


export const query = graphql`
query($slug: String!)  {
  allMovieJson(filter: {fields: {slug: {eq: $slug}}}) {
    nodes {
      date
      fields {
          slug
          }
          niconico{
            thumbnail_url
            video_url
          }
          youtube
          tiktok{
            thumbnail_url
            video_url
          }
          title
          rough_genre
          top_image
          }
      }
  }
`
