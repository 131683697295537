import PropTypes from 'prop-types'
import React from 'react'
import './scss/main.scss'

class Popup extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return(
        <div
          ref={this.props.setWrapperRef}
          id="main"
          style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
          >
        {
          Object.values(this.props.node).map(
            (onelink,index) =>{
                switch (this.props.type){
                    case 'youtube':{
                      var youtubeVideoUrl = "https://www.youtube.com/embed/"+onelink + "?enablejsapi=1"

                    return(
                      <article
                        id={onelink}
                        className={`${this.props.article === youtubeVideoUrl ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                        }`}
                        style={{ display: 'none' }}
                      >
                        <iframe id={youtubeVideoUrl} style={{width:"100%", height:"100%"}} src={youtubeVideoUrl} seamless sandbox="allow-scripts allow-same-origin allow-presentation"></iframe>
                        {close}
                      </article>
                      )
                    }
                    case 'niconico':
                      {
                        var nicoVideoUrl = onelink.video_url + "?jsapi=1&playerId="+(index+1).toString()
                      return(
                          <article
                            id={onelink.video_url}
                            className={`${this.props.article === nicoVideoUrl ? 'active' : ''} ${
                            this.props.articleTimeout ? 'timeout' : ''
                            }`}
                            style={{ display: 'none' }}
                          >
                            <iframe src={nicoVideoUrl} id={nicoVideoUrl} style={{width:"100%", height:"100%"}}/>
                            {close}
                          </article>
                        )
                      }
                    case 'tiktok':
                      {
                        var tiktokVideoUrl = onelink.video_url + "?jsapi=1&playerId="+(index+1).toString()
                      return(
                          <article
                            id={onelink.video_url}
                            className={`${this.props.article === tiktokVideoUrl ? 'active' : ''} ${
                            this.props.articleTimeout ? 'timeout' : ''
                            }`}
                            style={{ display: 'none' }}
                            >
                            <iframe src={tiktokVideoUrl} id={tiktokVideoUrl} style={{width:"100%", height:"100%"}}/>
                            {close}
                          </article>
                      )
                    }
                    default:
                      return(
                        <>
                        </>
                      )
                    
                  }
            }
        )
}
</div>
    )
  }
}

Popup.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Popup
